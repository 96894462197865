<template>
  <div class="vx-row">
    <div class="vx-col md:w-1/1 w-full">
      <vx-card title="Change Password" class="mb-base" style="min-height: 100%">
        <div class="vx-row mr-4">
          <div class="vx-col w-full" style="display: flex">
            <div class="w-1/1 w-full ml-3">
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-1/5 w-full">
                  <span>Current Password</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input
                    type="password"
                    v-validate="'required'"
                    name="Current Password"
                    class="w-full"
                    v-model="data.current_password"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('current_password')"
                    >{{ errors.first("current_password") }}
                  </span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-1/5 w-full">
                  <span>New Password</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input
                    type="password"
                    v-validate="'required'"
                    name="New Password"
                    class="w-full"
                    v-model="data.new_password"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('new_password')"
                    >{{ errors.first("new_password") }}
                  </span>
                </div>
              </div>
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-1/5 w-full">
                  <span>Confirm Password</span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-input
                    type="password"
                    v-validate="'required'"
                    name="Confirm Password"
                    class="w-full"
                    v-model="data.confirm_password"
                  />
                  <span
                    class="text-danger text-sm"
                    v-show="errors.has('confirm_password')"
                    >{{ errors.first("confirm_password") }}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="vx-col w-full mt-5" style="display: flex">
            <div class="w-1/1 w-full ml-3">
              <div class="vx-row mb-3 mr-4">
                <div class="font-semibold vx-col sm:w-1/5 w-full">
                  <span></span>
                </div>
                <div class="vx-col sm:w-4/5 w-full">
                  <vs-button @click="handleSubmit()" class="mr-4"
                    >Submit</vs-button
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>
<script>
export default {
  created() {
    // this.getData()
  },
  data() {
    return this.initialState();
  },
  methods: {
    initialState() {
      return {
        data: {
          current_password: "",
          new_password: "",
          confirm_password: "",
        },
      };
    },
    paramData() {
      return {
        current_password: this.data.current_password,
        new_password: this.data.new_password,
      };
    },
    handleSubmit() {
      this.$validator.validateAll().then((result) => {
        if (this.data.new_password.length < 8) {
          this.errors.add({
            field: "new_password",
            msg: "The new password must be at least 8 characters",
          });
          result = false;
        }

        if (this.data.confirm_password.length < 8) {
          this.errors.add({
            field: "confirm_password",
            msg: "The confirm password must be at least 8 characters",
          });
          result = false;
        }

        // if (this.data.current_password.length < 8) {
        //     this.errors.add({
        //         field: "current_password",
        //         msg: "The current password must be at least 8 characters"
        //     });
        //     result = false;
        // }

        if (this.data.new_password.length == "") {
          this.errors.add({
            field: "new_password",
            msg: "The new password is required",
          });
          result = false;
        }

        if (this.data.confirm_password.length == "") {
          this.errors.add({
            field: "confirm_password",
            msg: "The confirm password is required",
          });
          result = false;
        }

        if (this.data.current_password.length == "") {
          this.errors.add({
            field: "current_password",
            msg: "The current password is required",
          });
          result = false;
        }

        if (this.data.new_password !== this.data.confirm_password) {
          this.errors.add({
            field: "confirm_password",
            msg: "The confirm password doesn't match",
          });
          result = false;
        }

        if (result) {
          this.$vs.loading();
          this.putData();
        }

        // console.log(this.paramData())
      });
    },
    putData() {
      this.$http
        .patch("/api/account/v1/profile/change-password", this.paramData())
        .then((resp) => {
          this.$vs.loading.close();
          if (resp.code == 200) {
            this.$vs.notify({
              color: "success",
              title: "Success",
              text: "Password has change",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
            setInterval(() => {
              location.reload();
            }, 2000);
          } else {
            this.$vs.notify({
              color: "danger",
              title: "Error",
              text: resp.message,
              position: "top-right",
              iconPack: "feather",
              icon: "icon-x-circle",
            });
          }
        })
        .catch((error) => {
          this.$vs.loading.close();
          console.log(error);
        });
    },
  },
  watch: {
    "data.current_password": function (val) {
      // val = val.toString().replace(/\./g, "");
      if (val == "") {
        this.errors.add({
          field: "current_password",
          msg: "The current password field is required",
        });
      } else {
        this.errors.clear();
      }
    },
    "data.new_password": function (val) {
      // val = val.toString().replace(/\./g, "");
      if (val == "") {
        this.errors.add({
          field: "new_password",
          msg: "The new password field is required",
        });
      } else if (val.length < 8) {
        this.errors.add({
          field: "new_password",
          msg: "The new password must be at least 8 characters",
        });
      } else {
        this.errors.clear();
      }
    },
    "data.confirm_password": function (val) {
      // val = val.toString().replace(/\./g, "");
      if (val == "") {
        this.errors.add({
          field: "confirm_password",
          msg: "The confirm password field is required",
        });
      } else if (val !== this.data.new_password) {
        this.errors.add({
          field: "confirm_password",
          msg: "The confirm password doesn't match",
        });
      } else {
        this.errors.clear();
      }
    },
  },
};
</script>
